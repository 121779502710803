import React, { useEffect, useState } from 'react';
import axios from 'axios';

const NewsFeed = () => {
    const [news, setNews] = useState([]);

    useEffect(() => {
        const fetchNews = async () => {
            try {
                const response = await axios.get('https://peyoye.com/api/news/news.php');
                setNews(response.data);
            } catch (error) {
                console.error('Error fetching news:', error);
            }
        };
        fetchNews();
    }, []);

    return (
        <div>
            <h1>Noticias Personalizadas</h1>
            {news.map((article) => (
                <div key={article.id}>
                    <h2>{article.title}</h2>
                    <p>{article.description}</p>
                    <a href={article.url} target="_blank" rel="noopener noreferrer">Leer más</a>
                </div>
            ))}
        </div>
    );
};

export default NewsFeed;